import React from 'react'

import * as ProjectAuthorization from '@arch-log/webapp.modules/project.authorization.v2/components'
import * as Permissions from '@arch-log/webapp.modules/project.authorization.v2/permissions'
import * as NoPermission from '@arch-log/webapp.shared/Errors/NoPermission'

/**
 */
export const Default = ({}) => {
  const hasPermission = ProjectAuthorization.Remote.Queries.MyEntry.useHasPermissions()

  if (!hasPermission(Permissions.NotificationGroupRead)) {
    return <NoPermission.Default />
  }

  return null
}
