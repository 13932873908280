import React from 'react'

import { Page as PageLoader } from '@arch-log/webapp.atomics/components/Loader'

import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as ProjectExtention from '@arch-log/webapp.modules/project-extention.v2/components'
import * as ProjectExtentionConsts from '@arch-log/webapp.modules/project-extention.v2/consts'

/**
 */
export const Loader = ({
  children,
  components: {
    Loader = () => <PageLoader>project extention loading</PageLoader>,
  } = {},
}) => {
  const [projectEntry] = Project.Scope.Entry.useEntry()
  const [load, { loading }] = ProjectExtention.Remote.Queries.Entry.useLoad()

  React.useEffect(() => {
    load({
      variables: {
        projectId: projectEntry?.uuid,
        name: ProjectExtentionConsts.ProjectExtension_Name,
      },
    })
  }, [])

  if (loading) {
    return <Loader />
  }

  return <>{children}</>
}

/**
 */
export const Validation = ({ children, onDeactive }) => {
  const [entry] = ProjectExtention.Scope.Entry.useEntry()

  if (entry?.state === 'DEACTIVE') {
    onDeactive()
  }

  return <>{children}</>
}
