import React from 'react'

import * as Containers from './Containers'

/**
 */
export const Default = ({
  children,
  onDeactive,
  components: { Loader: loaderComponents } = {},
}) => {
  return (
    <Containers.Loader components={loaderComponents}>
      <Containers.Validation onDeactive={() => onDeactive()}>
        {children}
      </Containers.Validation>
    </Containers.Loader>
  )
}
